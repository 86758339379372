<template>
  <v-container fluid class="px-3 pt-3 pb-0">
    <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
      <v-col cols="12" md="12" sm="12" v-if="errors.length > 0">
        <ul>
          <li v-for="(err, i) in errors" :key="i" class="red--text">
            {{ err }}
          </li>
        </ul>
      </v-col>
      <div class="d-flex justify-center align-center" v-if="loading">
        <v-progress-circular indeterminate color="info" :width="2"></v-progress-circular>
      </div>
      <div v-else>
        <div>
          <div class="d-flex align-center">
            <h3 class="text--primary font-weight-black mr-3 d-inline-block">Groups Access</h3>
            <v-btn
              color="orange"
              small
              @click="createNewGroupEntry()"
              class="font-weight-bold"
              :disabled="readonly"
              v-if="selected != null"
            >
              <i class="far fa-plus mr-2"></i>New
            </v-btn>
          </div>
          <!-- Access Groups  -->
          <v-data-table
            dense
            v-if="selected"
            :headers="groupHeaders"
            :items="selected.companyDefaultAccessGroupRecords"
            :items-per-page="-1"
            class="elevation-2 mt-2"
            :loading="loading"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
            </template>
            <template v-slot:[`item.isActive`]="{ item }">
              <v-switch
                class="ma-0 pa-0"
                color="info"
                hide-details
                inset
                dense
                :disabled="readonly"
                v-model="item.isActive"
              >
              </v-switch>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <access-groups-selector
                :accessGroups="accessGroups"
                required
                hideLabel
                hideDetails
                hideBorder
                :readonly="item.id != 0"
                :isAccessGroupsLoading="isAccessGroupsLoading"
                v-model="item.accessGroup"
                style="width: 100%"
                class="ml-0"
              ></access-groups-selector>
            </template>
            <template v-slot:[`item.actions`]="{ item, index }">
              <v-btn
                icon
                elevation="0"
                color="error"
                :disabled="readonly"
                @click.stop="delGroup(item, index)"
              >
                <i class="fal fa-trash-alt"></i>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <img width="300" src="/img/art/fogg-no-connection-2.png" />
              <p class="font-weight-bold">No Data Available!</p>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import enums from "../../../../plugins/enums";
import perms from "../../../../plugins/permissions";
import groupHeaders from "../config/tables/accessGroup.header";
import accessGroupsAPI from "../../../AccessGroups/services/access-group-service";
import AccessGroupsSelector from "../../../Projects/components/AccessGroupSelector.vue";

export default {
  name: "equipment-access-list",
  props: {
    readonly: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  data() {
    return {
      enums,
      hasChanges: false,
      loading: false,
      saveLoading: false,
      users: [],
      accessGroups: [],
      isUsersLoading: false,
      isAccessGroupsLoading: false,
      selected: null,
      groupHeaders: groupHeaders,
    };
  },
  created() {
    this.getAllGroupsData();
  },
  methods: {
    createNewGroupEntry() {
      this.selected.companyDefaultAccessGroupRecords.push({
        id: 0,
        isActive: true,
        accessGroupId: 0,
        companyId: this.selected.id,
      });
    },
    delGroup(item, index) {
      this.selected.companyDefaultAccessGroupRecords.splice(index, 1);
    },
    getAllGroupsData() {
      this.isAccessGroupsLoading = true;
      accessGroupsAPI
        .typeHead(null, true)
        .then((resp) => {
          this.accessGroups = resp.data;
          this.isAccessGroupsLoading = false;
        })
        .catch(() => {
          this.isAccessGroupsLoading = false;
        });
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        if (this.selected && this.selected.companyDefaultAccessGroupRecords)
          this.selected.companyDefaultAccessGroupRecords.forEach(
            (e) => (e.accessGroupId = e.accessGroup ? e.accessGroup.id : null)
          );

        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
    errors: {
      handler() {
        this.$emit("valid-changes", this.errors.length == 0);
      },
      deep: true,
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.ProjectAccessList.Update);
    },
    errors() {
      let errs = [];
      if (!this.selected) return errs;
      if (this.selected.companyDefaultAccessGroupRecords) {
        if (this.selected.companyDefaultAccessGroupRecords.some((e) => !e.accessGroupId))
          errs.push("Some Groups Access has no group selected!");
        var groupsIds = this.selected.companyDefaultAccessGroupRecords
          .filter((e) => e.accessGroupId)
          .map((e) => e.accessGroup.id);
        if (new Set([...groupsIds]).size != groupsIds.length)
          errs.push("Groups Access can't be duplicated!");
      }
      return errs;
    },
  },
  components: { AccessGroupsSelector },
};
</script>
