<template>
  <div>
    <slideout
      dock="right"
      :size="mainPanelSize"
      :min-size="nested ? 400 : 900"
      :allow-resize="nested ? false : false"
      :append-to="nested ? '' : '#app'"
      class="update-layout-template-slideout"
      :class="readonly ? 'slideout-readonly-content' : ''"
      :visible.sync="slideouts.update.active"
      v-on:close="onSlideoutClosing"
    >
      <template v-slot:header>
        <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
          <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Layout Template ...
        </h3>
        <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
          <span v-if="readonly">
            <i class="fad fa-pen-square mr-2"></i> Layout Template: '{{ selected.name }}'
          </span>
          <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
          <entity-id
            class="ml-2"
            :label="selected.id"
            :path="layoutTemplateUrl"
            title="Layout Template"
          ></entity-id>
        </h3>
        <h3 v-else class="font-weight-bold pa-1" small>
          <i class="fad fa-plus-square mr-2"></i> Create a Layout Template
        </h3>
        <div>
          <code
            v-if="readonly"
            class="text-caption ml-2 white secondary--text mr-2"
            style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
          >
            <span class="fa-stack mr-0">
              <i class="fad fa-pencil fa-stack-1x"></i>
              <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
            </span>
            Read-Only
          </code>
          <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
          <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>
      <div color="white" style="position: sticky; top: 0; z-index: 20">
        <v-tabs
          v-model="slideouts.update.tab"
          color="error"
          v-if="slideouts.update.active && !isFetching"
        >
          <v-tab :key="0"> Template Information </v-tab>
          <v-tab :key="1"> Layout </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>

      <v-container fluid class="pa-0 text-center" v-if="isFetching">
        <video
          width="320"
          muted
          loop
          autoplay
          style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
        >
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Fetching Space Template ...</p>
      </v-container>
      <v-container fluid class="pa-0" v-else>
        <v-form v-model="slideouts.update.valid" ref="updateForm">
          <v-tabs-items v-model="slideouts.update.tab">
            <v-tab-item :key="0">
              <v-container class="pa-6">
                <v-row class="my-0">
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-text-field-alt
                      :rules="[allRules.required, allRules.noWhiteSpaces]"
                      label="Name"
                      id="layoutTemplateName"
                      ref="layoutTemplateName"
                      placeholder="Layout Template Name"
                      v-model="selected.name"
                      :readonly="readonly"
                    >
                    </v-text-field-alt>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="py-0"> </v-col>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-textarea-alt
                      label="Description"
                      id="spaceTemplateDescription"
                      placeholder="Layout Description"
                      v-model="selected.description"
                      :rules="[allRules.noWhiteSpaces]"
                      no-resize
                      height="132px"
                      :readonly="readonly"
                    >
                    </v-textarea-alt>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <single-image-uploader
                      id="update-space-template-uploader"
                      :apiUrl="imageApiUrl"
                      v-model="selected.imageUrl"
                      label="Layout Template Image"
                      :disabled="readonly"
                    ></single-image-uploader>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="1">
              <layout-manager
                v-model="selected.layoutData"
                :disabled="readonly"
                parent-selector=".update-layout-template-slideout .vue-slideout-content"
              ></layout-manager>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div
            class="d-flex ml-2"
            v-if="!isFetching && readonly && isMainRoute && $has(perms.LayoutTemplates.Update)"
          >
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="switchToEditMode()"
                  :disabled="isFetching"
                  color="orange"
                >
                  <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex ml-2" v-if="!isFetching && !readonly">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="slideouts.update.isLoading"
                  @click="jumpToRequiredField()"
                  color="error"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  class="ml-2"
                  outlined
                >
                  <i class="fas fa-asterisk fs-14px"></i>
                </v-btn>
              </template>
              <span>Jump to Required Field</span>
            </v-tooltip>
          </div>

          <v-btn
            class="ml-2"
            v-if="!isFetching && !readonly && selected.id != null"
            :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
            @click="discardChanges()"
            color="secondary"
          >
            <i class="fal fa-clock-rotate-left mr-2"></i> Discard
          </v-btn>

          <div class="d-flex ml-2" v-if="!isFetching && !readonly">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="info"
                  :disabled="
                    !slideouts.update.valid ||
                    slideouts.update.isLoading ||
                    !slideouts.update.hasChanges
                  "
                  @click="updateConfirmed(false)"
                  :loading="slideouts.update.isLoading"
                >
                  <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                  {{ selected.id ? "Save" : "Create" }}
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >{{ selected.id ? "Save" : "Create"
                }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
              </span>
            </v-tooltip>
          </div>
          <div class="d-flex ml-2" v-if="!isFetching && !readonly">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="cyan white--text"
                  :disabled="
                    !slideouts.update.valid ||
                    slideouts.update.isLoading ||
                    !slideouts.update.hasChanges
                  "
                  @click="updateConfirmed(true)"
                  :loading="slideouts.update.isLoading"
                >
                  <i class="fas fa-save mr-2"></i>
                  {{ selected.id ? "Save & Close" : "Create & Close" }}
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                  class="light ml-2 fs-12px"
                  >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
                >
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex ml-2" v-if="selected.id != null">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  :loading="isFetching"
                  :disabled="
                    slideouts.update.isLoading || isFetching || slideouts.update.hasChanges
                  "
                  @click="fetchTemplate()"
                >
                  <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
                </v-btn>
              </template>
              <span>Refetch Layout Template</span>
            </v-tooltip>
          </div>
          <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
                :elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <v-list class="more-options-menu">
              <v-list-item @click="del" v-if="$has(perms.LayoutTemplates.Delete)">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-trash-alt red--text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="red--text">Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-subheader
                class="font-weight-bold"
                style="height: auto"
                v-if="!$has(perms.LayoutTemplates.Update) && !$has(perms.LayoutTemplates.Delete)"
              >
                No Actions Available!
              </v-subheader>
            </v-list>
          </v-menu>
        </v-card-actions>
      </template>
    </slideout>
  </div>
</template>

<script>
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import perms from "../../../plugins/permissions";
import layoutTemplatesService from "../services/layoutTemplates-service";
import LayoutManager from "./LayoutManager.vue";

export default {
  name: "edit-layout-template",
  data() {
    return {
      perms: perms,
      templateId: null,
      isFetching: false,
      readonly: false,
      isViewsLoading: false,
      views: [],
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          valid: false,
          fullWidth: false,
          tab: null,
          hasChanges: null,
          isLoading: false,
          categoryLoading: false,
        },
        addEquipments: {
          active: false,
          isLoading: false,
        },
      },
      imageApiUrl: `DrawingViewLayout/Image`,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.isMainRoute &&
        this.$has(this.perms.LayoutTemplates.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementTemplate();
      this.$releaseChanges();
      this.isFetching = false;
      this.expandedAreaGroupPanel = null;

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewTemplate) {
      this.$emit("save", this.selected, isNewTemplate);
    },
    fetchTemplate() {
      this.isFetching = true;
      layoutTemplatesService
        .getTemplateById(this.templateId)
        .then((resp) => {
          this.$log("getTemplateById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.selected.layoutData = JSON.parse(this.selected.layoutData);
          this.cementTemplate();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
          this.handleFocus();
        })
        .catch((err) => {
          this.$log("getTemplateById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    open(id, editMode = false) {
      if (id == null) {
        //new template
        this.selected = {
          name: "",
          description: "",
          imageUrl: "",
          layoutData: { pages: [] },
          viewIds: [],
        };
        this.readonly = false;
        this.isFetching = false;
        this.templateId = null;
        this.slideouts.update.tab = 0;
        this.$refs.updateForm.resetValidation();
        this.handleFocus();
        this.cementTemplate();
      } else {
        this.templateId = id;
        this.readonly = !editMode;
        this.slideouts.update.tab = 0;
        this.fetchTemplate();
      }
      this.slideouts.update.active = true;
      // this.cementTemplate();
    },
    cementTemplate() {
      this.selectedCemented = this.cloneDeep(this.selected);
      // this.$log("########>>>>>>> cementTemplate()");
      this.checkForChanges();
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = this.cloneDeep(this.selected);
      toSend.layoutData = JSON.stringify(toSend.layoutData);
      this.$log("update >> toSend", toSend);
      layoutTemplatesService
        .update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          this.$log("update >> Success", resp);
          var message = "Layout template is updated successfully!";
          let updatedTemplate = this.cloneDeep(resp.data);
          updatedTemplate.layoutData = JSON.parse(updatedTemplate.layoutData);
          this.selected = this.cloneDeep(updatedTemplate);
          this.cementTemplate();
          var isNewTemplate = false;
          if (!toSend.id) {
            this.templateId = this.selected.id;
            isNewTemplate = true;
            message = "Layout template is added successfully!";
          }
          this.announceChange(isNewTemplate);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.$log("update >> error", err);
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    handleFocus() {
      if (this.isFetching) return;
      if (this.slideouts.update.tab == 0) {
        setTimeout(() => {
          this.$refs.layoutTemplateName.focus();
        }, 350);
      }
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: ` Are you sure you want to delete this layout template?<br/><h4>${this.selected.name}</h4>`,
          title: `Delete Layout Template?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return layoutTemplatesService
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.onDeleteLayoutTemplateSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Layout Template deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Layout Template!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDeleteLayoutTemplateSuccess(id) {
      this.$emit("delete", id);
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "900px";
    },
    layoutTemplateUrl() {
      if (this.selected == null) return "";
      return `layout-templates/${this.selected.id}`;
    },
    spaceEquipmentCount() {
      if (this.selected != null && this.selected.areaGroups != null)
        return this.selected.areaGroups.reduce((total, cur) => {
          return total + this.getAreaGroupEquipmentsCount(cur);
        }, 0);
      else return 0;
    },
    isMainRoute() {
      if (this.$route.name == "layout-template") return true;
      else return false;
    },
  },
  watch: {
    "selected.name": {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "selected.description": {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "selected.imageUrl": {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "selected.layoutData": {
      handler() {
        if (this.slideouts.update.active) {
          this.checkForChanges();
        }
      },
      deep: true,
    },
    "slideouts.update.tab": {
      handler() {
        this.handleFocus();
      },
      deep: true,
    },
    "slideouts.addEquipments.active": {
      handler() {
        if (this.slideouts.addEquipments.active) {
          setTimeout(() => {
            this.$refs.addEquipmentsControl.focus();
          }, 350);
        }
      },
      deep: true,
    },
  },
  created() {},
  components: { LayoutManager, PanelSizeControl },
};
</script>

<style lang="scss">
.update-layout-template-slideout {
  .v-window.v-tabs-items {
    overflow: visible !important;
  }
}
</style>
