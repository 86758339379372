<template>
  <v-expansion-panels
    v-if="areaGroups.length > 0"
    multiple
    :value="Array.from(Array(areaGroups.length).keys())"
  >
    <v-expansion-panel v-for="(areaGroup, i) in areaGroups" :key="i">
      <v-expansion-panel-header class="px-4 py-0" :ripple="true">
        <v-row no-gutters justify="space-between">
          <v-col cols="auto" class="d-flex align-center">
            <span class="font-weight-bold" style="pointer-events: none">
              <i class="fad fa-shapes mr-2"></i>
              <span class="mr-2">{{ areaGroup.name }}</span>
              <v-chip
                v-if="areaGroup.diffs && areaGroup.diffs.nameChanged"
                x-small
                color="brown"
                dark
              >
                Name Changed
              </v-chip>
              <v-chip
                v-if="areaGroup.diffs && areaGroup.diffs.equipmentUpdated"
                x-small
                color="orange"
              >
                Equipment Updated
              </v-chip>
              <v-chip
                v-else-if="areaGroup.diffs && areaGroup.diffs.deleted"
                x-small
                dark
                color="error"
              >
                Deleted
              </v-chip>
              <v-chip v-else-if="areaGroup.diffs && areaGroup.diffs.new" x-small dark color="green">
                New
              </v-chip>
              <v-chip
                v-else-if="areaGroup.diffs"
                color="grey lighten-1"
                x-small
                outlined
                class="ml-2"
              >
                No Equipment Change
              </v-chip>
            </span>
          </v-col>
          <v-col cols="auto" class="d-flex align-center"> </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="logged-changes-panel-content">
        <div>
          <v-divider class="mb-1"></v-divider>
          <div class="py-2 px-4 mb-1">
            <v-simple-table dense class="elevation-1">
              <template v-slot:default>
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-start" style="width: 300px; min-width: 300px">Equipment</th>
                    <th class="text-start">Count</th>
                    <th class="text-start"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(equipment, j) in areaGroup.equipments" :key="j">
                    <th class="text-start">
                      <div class="d-flex align-center fs-12px">
                        <i class="fad fa-tag pr-1"></i>
                        <b class="mono-font" style="min-width: 90px">{{ equipment.tag }}</b>
                        <i v-if="equipment.model != null" class="fad fa-barcode pr-1 pl-4"></i>
                        <b v-if="equipment.model != null" class="mono-font">{{
                          equipment.model
                        }}</b>
                        <entity-id
                          class="ml-2"
                          :path="`equipment/${equipment.id}`"
                          title="Equipment"
                          hideLabel
                          tooltipOnTop
                        ></entity-id>
                      </div>
                    </th>
                    <th class="text-start">
                      {{ equipment.count }}
                    </th>
                    <th class="text-start">
                      <v-chip
                        v-if="
                          (equipment.diffs && equipment.diffs.deleted) ||
                          (areaGroup.diffs && areaGroup.diffs.deleted)
                        "
                        x-small
                        dark
                        color="error"
                      >
                        Deleted
                      </v-chip>
                      <v-chip
                        v-else-if="
                          (equipment.diffs && equipment.diffs.new) ||
                          (areaGroup.diffs && areaGroup.diffs.new)
                        "
                        x-small
                        dark
                        color="green"
                      >
                        New
                      </v-chip>
                      <v-chip
                        v-else-if="equipment.diffs && equipment.diffs.countChanged"
                        x-small
                        dark
                        color="purple"
                      >
                        Count Changed
                      </v-chip>
                      <v-chip v-else-if="areaGroup.diffs" color="grey lighten-1" x-small outlined>
                        No Change
                      </v-chip>
                    </th>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <i v-else class="fad fa-empty-set opacity-64 fs-16px mt-1"></i>
</template>

<script>
import Enums from "../../../../plugins/enums";

export default {
  name: "area-groups-entry-log",
  components: {},
  data() {
    return {
      ActivityLogType: Enums.ActivityLogType,
    };
  },
  props: {
    areaGroups: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss"></style>
