export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "64px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  { text: "Name", value: "name", order: 1, hidable: false, hidden: false },
  {
    text: "Abbreviated Name",
    value: "abbreviatedName",
    order: 2,
    hidable: true,
    hidden: false,
  },
  {
    text: "Contacts",
    value: "contactsCount",
    order: 3,
    hidable: true,
    hidden: false,
  },
  { text: "Projects", value: "projectsCount", order: 4, hidable: true, hidden: false },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "130px",
    order: 6,
    hidable: true,
    hidden: false,
  },
  {
    text: "Created By",
    value: "createdBy",
    order: 7,
    hidable: true,
    hidden: true,
  },
  {
    text: "Updated By",
    value: "updatedBy",
    order: 8,
    hidable: true,
    hidden: true,
  },
];
