<template>
  <v-container fluid class="pa-5 pt-0">
    <div
      class="layout-editor-header d-flex flex-row justify-space-between align-center pa-2 white"
      style="width: 100%; position: sticky; top: 41px; z-index: 19"
    >
      <h4 class="ma-0">Layout Editor</h4>
      <v-fade-transition mode="out-in">
        <div :key="'create-new-menu'">
          <v-menu offset-y left :z-index="200">
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                text
                depressed
                ref="addPageBtn"
                class="action-btn"
                :key="'add-new-page-btn'"
                :disabled="disabled"
              >
                <i class="fad fa-plus-circle mr-2"></i> New Page
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                link
                :key="'add-arch-e-page-btn'"
                style="min-height: 32px"
                @click="addNewPage('arch-e-landscape')"
              >
                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                  <span
                    class="fa-stack mr-2"
                    style="font-size: 20px; width: 20px; height: 20px; line-height: 20px"
                  >
                    <i
                      class="fad fa-rectangle fa-stack-1x text--secondary"
                      style="font-size: 20px"
                    ></i>
                    <i class="fas fa-e fa-stack-1x" style="font-size: 11px; margin-top: 1px"></i>
                  </span>
                  <span class="mono-font">Arch E</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px !important; line-height: 20px"
                    class="font-weight-medium text--secondary"
                  >
                    48″ x 36″
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                :key="'add-arch-d-page-btn'"
                style="min-height: 32px"
                @click="addNewPage('arch-d-landscape')"
              >
                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                  <span
                    class="fa-stack mr-2"
                    style="font-size: 20px; width: 20px; height: 20px; line-height: 20px"
                  >
                    <i
                      class="fad fa-rectangle fa-stack-1x text--secondary"
                      style="font-size: 20px"
                    ></i>
                    <i class="fas fa-d fa-stack-1x" style="font-size: 11px; margin-top: 1px"></i>
                  </span>
                  <span class="mono-font">Arch D</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px !important; line-height: 20px"
                    class="font-weight-medium text--secondary"
                  >
                    36″ x 24″
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                :key="'add-arch-b-page-btn'"
                style="min-height: 32px"
                @click="addNewPage('arch-b-landscape')"
              >
                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                  <span
                    class="fa-stack mr-2"
                    style="font-size: 20px; width: 20px; height: 20px; line-height: 20px"
                  >
                    <i
                      class="fad fa-rectangle fa-stack-1x text--secondary"
                      style="font-size: 20px"
                    ></i>
                    <i class="fas fa-b fa-stack-1x" style="font-size: 11px; margin-top: 1px"></i>
                  </span>
                  <span class="mono-font">Arch B</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px !important; line-height: 20px"
                    class="font-weight-medium text--secondary"
                  >
                    18″ x 12″
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                :key="'add-arch-super-b-page-btn'"
                style="min-height: 32px"
                @click="addNewPage('arch-super-b-landscape')"
              >
                <v-list-item-icon class="mr-2 d-flex justify-center align-center">
                  <span
                    class="fa-stack mr-2"
                    style="font-size: 20px; width: 20px; height: 20px; line-height: 20px"
                  >
                    <i
                      class="fad fa-shield fa-stack-1x text--secondary"
                      style="font-size: 20px"
                    ></i>
                    <i class="fas fa-b fa-stack-1x" style="font-size: 11px; margin-top: 1px"></i>
                  </span>
                  <span class="mono-font">Arch Super B</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px !important; line-height: 20px"
                    class="font-weight-medium text--secondary"
                  >
                    19″ x 13″
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-fade-transition>

      <v-progress-linear
        indeterminate
        color="info"
        absolute
        top
        v-show="fetchingViews"
      ></v-progress-linear>
    </div>
    <div
      style="background: #eceff1; border-radius: 0 0 0.5rem 0.5rem; min-height: 326px"
      class="pa-5 inset-shadow"
    >
      <v-scroll-y-transition mode="out-in">
        <div
          v-if="layoutPagesAreLoaded && viewsAreLoaded && layoutData.pages.length > 0"
          class="d-flex flex-column"
          style="gap: 1rem"
          :key="'doc-layout-pages-available'"
        >
          <v-scroll-y-transition
            mode="out-in"
            v-for="(page, pageIndex) in layoutData.pages"
            :key="'tp' + page.pageNum"
          >
            <layout-page
              class="d-flex flex-column"
              v-if="!layoutData.pages[pageIndex].hidden"
              v-model="layoutData.pages[pageIndex]"
              :total-pages="layoutData.pages.length"
              :views="views"
              @delete="onPageDelete(page, pageIndex)"
              @view-assigned="onViewAssigned($event, pageIndex)"
              :key="'p' + page.pageNum"
              :disabled="disabled"
            ></layout-page>
          </v-scroll-y-transition>
        </div>
        <div class="no-pages-added" :key="'doc-layout-no-pages-available'" v-else>
          <img src="/img/art/tumble-weed.svg" class="mt-3" />
          <!-- <p class="font-weight-medium mb-5 text--disabled">No documents available in this folder!</p> -->
          <p class="font-weight-medium mb-5 text--disabled">No pages added yet!</p>
        </div>
      </v-scroll-y-transition>
    </div>
  </v-container>
</template>

<script>
import LayoutPage from "./LayoutPage.vue";
import layoutViewsService from "../services/layout-views-service.js";

export default {
  name: "layout-manager",
  data() {
    return {
      layoutData: null,
      fetchingViews: true,
      viewsAreLoaded: false,
      views: [],
      viewsOptions: {
        search: null,
        page: 1,
        itemsPerPage: -1,
      },
    };
  },
  computed: {},
  props: {
    value: {
      type: Object,
      default: null,
    },
    parentSelector: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  created() {
    this.fetchViewsList();
  },
  mounted() {
    this.layoutData = this.cloneDeep(this.value);
    this.$log("this.layoutData", this.cloneDeep(this.layoutData));
  },
  methods: {
    fetchViewsList() {
      this.$log("fetchViewsList");
      this.fetchingViews = true;
      layoutViewsService
        .query(this.$clean(this.viewsOptions, true))
        .then((resp) => {
          this.views = resp.data.items;
          this.$log("fetchViewsList Success", this.views);
          this.fetchingViews = false;
          this.viewsAreLoaded = true;
        })
        .catch((err) => {
          this.$log("fetchViewsList err", err);
          this.fetchingViews = false;
          this.$handleError(err);
        });
    },
    onViewAssigned({ viewId, col, colIndex, row, rowIndex, page }, pageIndex) {
      //traverse all pages and remove the assigned view from all columns but the newly assigned one
      // this.$log("onViewAssigned", viewId, col, colIndex, row, rowIndex, page, pageIndex);
      for (let p = 0; p < this.layoutData.pages.length; p++) {
        const _page = this.layoutData.pages[p];

        for (let r = 0; r < _page.rows.length; r++) {
          const _row = _page.rows[r];

          for (let c = 0; c < _row.cols.length; c++) {
            const _col = _row.cols[c];

            if (p == pageIndex && r == rowIndex && c == colIndex) continue;
            if (viewId == _col.viewId) _col.viewId = null;
          }
        }
      }
    },
    onPageDelete(page, index) {
      this.layoutData.pages[index].hidden = true;
      // setTimeout(() => {
      this.layoutData.pages.splice(index, 1);
      //update page nums
      for (let i = 0; i < this.layoutData.pages.length; i++) {
        const page = this.layoutData.pages[i];
        page.pageNum = i + 1;
      }
      this.$emit("update:layoutData", this.layoutData);
      // }, 500);
    },
    addNewPage(pageSize) {
      let page = {
        pageNum: this.layoutData.pages.length + 1,
        pageSize: pageSize,
        hidden: false,
        rows: [
          {
            rowNum: 1,
            colsCount: 2,
            cols: [
              {
                colNum: 1,
                viewId: null,
              },
              {
                colNum: 2,
                viewId: null,
              },
            ],
          },
        ],
      };
      this.layoutData.pages.push(page);
      if (this.parentSelector != null) {
        let elm = document.querySelector(this.parentSelector);
        if (elm)
          setTimeout(() => {
            elm.scrollTo({ top: elm.scrollHeight, behavior: "smooth" });
          }, 50);
      }
      this.$emit("update:layoutData", this.layoutData);
    },
  },
  computed: {
    layoutPagesAreLoaded() {
      return (
        this.layoutData != null &&
        this.layoutData.pages != null &&
        this.layoutData.pages.length >= 0
      );
    },
  },
  watch: {
    layoutData: {
      handler() {
        this.$emit("input", this.layoutData);
      },
      deep: true,
    },
    value: {
      handler() {
        if (!this.isEqual(this.layoutData, this.value)) {
          this.layoutData = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
  },
  components: { LayoutPage },
};
</script>

<style lang="scss">
.no-pages-added {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 300px;
    // opacity: 0.87;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
.layout-editor-header {
  display: block;
  background: #fff;
  margin-bottom: -2px;

  &:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 0;
    width: calc(100% - 4px);
    height: 100%;
    z-index: -2;
    box-shadow: 0px 2px 1px -1px rgba($shades-black, 0.16), 0px 1px 1px 0px rgba($shades-black, 0.1),
      0px 1px 3px 0px rgba($shades-black, 0.08) !important;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #fff;
  }
}
</style>
