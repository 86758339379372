<template>
  <div class="comp-wrpr naming-templates-selector">
    <v-autocomplete-alt
      auto-select-first
      :rules="required ? [allRules.required] : []"
      :label="
        hideLabel
          ? ''
          : label == null || label == ''
          ? `<i class='fad fa-pen-field mr-2'></i>Naming Template`
          : label
      "
      id="namingTemplate"
      placeholder="No Template Selected!"
      :value="value"
      @input="emitTemplate"
      :items="templates"
      :search-input.sync="options.search"
      dense
      filled
      clearable
      :hide-details="hideDetails"
      item-value="id"
      item-text="name"
      :loading="isTemplatesLoading"
      :readonly="readonly"
      :menu-props="{
        zIndex: 90000,
        contentClass: 'naming-templates-selector-menu',
      }"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <div
          :key="item + '_namingTemplateSelector'"
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); width: 100%; flex-wrap: nowrap"
          class="py-0"
        >
          <strong class="fs-14px">{{ data.item.name }}</strong>
          <div class="d-flex align-center" style="gap: 1rem">
            <span class="d-flex align-center">
              <i class="fad fa-input-text mr-1"></i>
              <naming-template-scheme-view
                :templateText="data.item.documentNameTemplate"
                tiny
              ></naming-template-scheme-view>
            </span>
            <span class="opacity-40">|</span>
            <span class="d-flex align-center">
              <i class="fad fa-input-numeric mr-1"></i>
              <naming-template-scheme-view
                :templateText="data.item.documentNumberTemplate"
                tiny
              ></naming-template-scheme-view>
            </span>
          </div>
        </div>
      </template>
      <template #append-item>
        <div v-intersect="loadMore">
          <span v-if="isTemplatesLoading" class="mb-4 pa-2">Loading More Results...</span>
        </div>
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      :loading="isTemplatesLoading"
      :disabled="isTemplatesLoading || readonly"
      @click="getData"
    >
      <i class="fas fa-sync"></i>
    </v-btn>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      @click="viewNamingTemplate(value)"
      :disabled="value == null"
      v-if="allowView"
    >
      <i class="fas fa-eye"></i>
    </v-btn>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      :href="`/naming-templates/${value}`"
      target="_blank"
      :disabled="value == null"
      v-if="allowView"
    >
      <i class="fas fa-external-link"></i>
    </v-btn>
    <edit-naming-template ref="viewNamingTemplate" :nested="nested"></edit-naming-template>
  </div>
</template>

<script>
import EditNamingTemplate from "./EditNamingTemplate.vue";
import namingTemplatesService from "../services/namingTemplates-service";
import NamingTemplateSchemeView from "./NamingTemplateSchemeView.vue";

export default {
  components: { EditNamingTemplate, NamingTemplateSchemeView },
  props: {
    value: {
      type: [Number, String],
    },
    obj: {
      type: Object,
    },
    documentType: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    allowView: {
      type: Boolean,
      default: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      templates: [],
      isTemplatesLoading: false,
      templatesTimerId: null,
      total: 0,
      options: {
        search: null,
        page: 1,
        itemsPerPage: -1,
      },
    };
  },
  methods: {
    emitTemplate(id) {
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.templates.find((elm) => elm.id == id) : null);
    },
    getData() {
      this.isTemplatesLoading = true;
      namingTemplatesService
        .queryByDocumentType({
          itemsPerPage: -1,
          search: this.options.search || "",
          documentType: this.documentType ?? 0,
        })
        .then((resp) => {
          const isSearch = !!this.options.search;
          this.addUniqueArr(this.templates, resp.data.items, "id", isSearch);
          if (!isSearch) this.total = resp.data.total;
          this.refreshAutocomplete();
          this.isTemplatesLoading = false;
        })
        .catch(() => {
          this.isTemplatesLoading = false;
        });
    },
    getDataDebounced() {
      if (this.templatesTimerId == null) {
        this.templatesTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.templatesTimerId);
      // delay new call 400ms
      this.templatesTimerId = setTimeout(() => {
        this.getData();
      }, 500);
    },
    refreshAutocomplete() {
      if (this.value && (!this.obj || this.value != this.obj.id)) {
        const index = this.templates.findIndex((elm) => elm.id == this.value);
        if (index == -1) {
          this.fetchObj();
        }
      } else {
        if (
          this.obj &&
          (this.templates.findIndex((elm) => elm.id == this.obj.id) == -1 ||
            this.value != this.obj.id)
        ) {
          this.addAndEmit(this.obj);
        }
      }
    },
    loadMore(undefine, _, isIntersecting) {
      if (isIntersecting && this.total > this.templates.length && !this.isTemplatesLoading) {
        // this line to loadMore if there is no search
        if (!this.options.search) this.options.page++;
        // this line to clear search only if there is item selected
        if (this.value) this.options.search = "";
      }
    },
    unshift(item) {
      this.addUniqueArr(this.templates, [item], "id", true);
    },
    viewNamingTemplate(id) {
      this.$refs.viewNamingTemplate.open(id);
    },
    addAndEmit(item) {
      this.addUniqueArr(this.templates, [item], "id", true);
      this.emitTemplate(item.id);
    },
    fetchObj() {
      namingTemplatesService.getTemplateById(this.value).then((res) => {
        this.addAndEmit(res.data);
      });
    },
  },
  created() {
    this.getDataDebounced();
  },
  watch: {
    options: {
      handler() {
        this.getDataDebounced();
      },
      deep: true,
    },
    documentType: {
      handler() {
        this.$log("documentType", this.documentType);
        this.templates = [];
        this.options.page = 1;
        this.getData();
      },
    },
    obj() {
      this.refreshAutocomplete();
    },
  },
};
</script>
<style lang="scss">
.comp-wrpr.naming-templates-selector {
  .v-autocomplete-alt {
    width: calc(100% - 40px);
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.naming-templates-selector-menu {
  max-width: 500px;
}

.typeahead-naming-name {
  max-width: calc(100% - 40px) !important;
}

.typeahead-naming-description {
  max-width: calc(100% - 40px) !important;
}
</style>
