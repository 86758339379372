<template>
  <v-container fluid class="pa-3" v-if="selected != null">
    <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
      <v-form v-model="valid" ref="projectVariablesForm">
        <v-simple-table dense class="elevation-1">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th class="text-start" style="width: 200px; min-width: 200px">Variable</th>
                <th class="text-start">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fal" :class="variableTypes.Number.icon"></i>
                        </div>
                      </template>
                      <span>{{ variableTypes.Number.text }}</span>
                    </v-tooltip>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Installation Factor</h4>
                      <h5 class="mb-1" style="opacity: 0.64; margin-top: 2px">
                        installation-factor
                      </h5>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <v-text-field-alt
                    :rules="[allRules.number, allRules.requiredNumber]"
                    placeholder="Add valid number"
                    v-model.number="selected.installationFactor"
                    hide-details
                    class="d-inline-block"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fal" :class="variableTypes.RichText.icon"></i>
                        </div>
                      </template>
                      <span>{{ variableTypes.RichText.text }}</span>
                    </v-tooltip>
                    <div>
                      <h4 class="mt-1 font-weight-bold">System Description</h4>
                      <h5 class="mb-1" style="opacity: 0.64; margin-top: 2px">
                        system-description
                      </h5>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <div :key="'editor-block'">
                    <rich-text-editor
                      v-model="selected.systemDescription"
                      title="System Description"
                      :readonly="readonly"
                      allowExpand
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <h2 class="font-weight-black py-3">Naming Templates</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.IAV.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">IAV</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.iavNamingTemplateId"
                    :obj.sync="selected.iavNamingTemplate"
                    :document-type="DocumentType.IAV.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.SCD.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">SCD</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.scdNamingTemplateId"
                    :obj.sync="selected.scdNamingTemplate"
                    :document-type="DocumentType.SCD.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.CoverSheet.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Cover Sheet</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.coverSheetNamingTemplateId"
                    :obj.sync="selected.coverSheetNamingTemplate"
                    :document-type="DocumentType.CoverSheet.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.Plan.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Plan Sheet</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.planNamingTemplateId"
                    :obj.sync="selected.planNamingTemplate"
                    :document-type="DocumentType.Plan.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.Overview.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Overview Sheet</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.overviewNamingTemplateId"
                    :obj.sync="selected.overviewNamingTemplate"
                    :document-type="DocumentType.Overview.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.Detail.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Detail Sheet</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.detailNamingTemplateId"
                    :obj.sync="selected.detailNamingTemplate"
                    :document-type="DocumentType.Detail.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.ScopeView.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Scope View</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.scopeViewNamingTemplateId"
                    :obj.sync="selected.scopeViewNamingTemplate"
                    :document-type="DocumentType.ScopeView.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.SitePlan.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Site Plan</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.sitePlanNamingTemplateId"
                    :obj.sync="selected.sitePlanNamingTemplate"
                    :document-type="DocumentType.SitePlan.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.SpecSheet.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">SPEC</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.specNamingTemplateId"
                    :obj.sync="selected.specNamingTemplate"
                    :document-type="DocumentType.SpecSheet.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.TypicalIAV.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Typical IAV</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.typicalIavNamingTemplateId"
                    :obj.sync="selected.typicalIavNamingTemplate"
                    :document-type="DocumentType.TypicalIAV.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.TypicalSCD.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Typical SCD</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    nested
                    :readonly="readonly"
                    v-model="selected.typicalScdNamingTemplateId"
                    :obj.sync="selected.typicalScdNamingTemplate"
                    :document-type="DocumentType.TypicalSCD.value"
                  ></naming-template-selector>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import clientVariableHeader from "../config/tables/clientVariable.header";
import { trim } from "lodash";
import Enums from "../../../../plugins/enums";
import RichTextEditor from "../../../Shared/components/RichTextEditor.vue";
import NamingTemplateSelector from "../../../DrawingDocuments/components/NamingTemplateSelector.vue";
import DocumentType from "../../../DrawingDocuments/components/DocumentType.vue";

export default {
  name: "client-variables",
  props: {
    value: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.selected = this.value;
  },
  data() {
    return {
      valid: false,
      selected: null,
      variableTypes: Enums.PROJECT_VARIABLE_TYPES,
      DocumentType: Enums.DOCUMENT_TYPES,
      headers: clientVariableHeader,
    };
  },
  methods: {},
  watch: {
    installationFactor: {
      handler() {
        this.selected.installationFactor = this.installationFactor;
      },
      deep: true,
    },
    systemDescription: {
      handler() {
        this.selected.systemDescription = this.systemDescription;
      },
      deep: true,
    },
    "selected.systemDescription": {
      handler() {
        this.$emit("update:system-description", this.selected.systemDescription);
      },
      deep: true,
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.value;
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
    "selected.installationFactor": {
      handler() {
        if (
          this.allRules.number(this.selected.installationFactor) == true &&
          this.selected.installationFactor != null &&
          trim(this.selected.installationFactor.toString()) != ""
        ) {
          this.$emit("update:installation-factor", this.selected.installationFactor);
        } else {
          this.selected.installationFactor = parseFloat(this.selected.installationFactor || 0);
        }
      },
      deep: true,
    },
  },
  components: { RichTextEditor, NamingTemplateSelector, DocumentType },
};
</script>

<style lang="scss"></style>
