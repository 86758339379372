export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "110px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Jira",
    icon: "fab fa-jira mr-1",
    value: "jiraCode",
    width: "110px",
    order: 1,
    hidable: false,
    hidden: false,
  },
  { text: "Client", value: "client", width: "92px", order: 2, hidable: false, hidden: false },
  { text: "Full Name", value: "fullName", order: 3, hidable: false, hidden: false },
  { text: "Status", value: "status", width: "120px", order: 4, hidable: true, hidden: false },

  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "130px",
    order: 6,
    hidable: true,
    hidden: false,
  },
];
