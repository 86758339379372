<template>
  <div class="d-flex" v-if="users.length">
    <user-avatar
      class="user-group"
      v-for="user in users.length > 5 ? 5 : users.length"
      :user="users[user - 1]"
      :key="user - 1 + 'users'"
      icon
      :style="`z-index: ${users.length - user};left:${(-user - 1) * 14}px`"
    ></user-avatar>
    <v-sheet
      v-if="users.length > 5"
      class="user-group d-flex align-center justify-center rounded-circle ma-1"
      :style="`z-index: 0; left:${-7 * 14}px`"
      width="28"
      height="28"
      color="grey lighten-2"
      >+{{ users.length - 5 }}</v-sheet
    >
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
    },
  },
};
</script>

<style>
.user-group {
  position: relative;
}
</style>
