var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-6 pt-2",staticStyle:{"background":"#eceff1"}},[_c('v-row',{staticClass:"mt-0 mb-2",attrs:{"justify":"center","align":"center"}},[_c('v-col',[(_vm.client)?_c('div',{staticClass:"d-flex align-center"},[(_vm.client.logoUrl != null && _vm.client.logoUrl != '')?_c('v-img',{attrs:{"src":_vm.client.logoUrl,"max-height":"40","max-width":"40","position":"left center","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","max-height":"40","max-width":"40","position":"left center","contain":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.client.name))])],1):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-row",staticStyle:{"gap":"0.5rem"},attrs:{"lg":"4","md":"6","sm":"12"}},[_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field d-inline-flex",style:({
            'max-width':
              _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
                ? '200px'
                : '110px',
          }),attrs:{"label":"Search","dense":"","solo":"","hide-details":"","clearable":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2 connected-projects-table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":15,"loading":_vm.loadingStates.table,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"header.jiraCode",fn:function(ref){
      var header = ref.header;
return [_c('i',{class:header.icon}),_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},{key:"item.jiraCode",fn:function(ref){
      var item = ref.item;
return [(item.jiraCode != null && item.jiraCode != '')?_c('a',{staticClass:"jira-external-link",attrs:{"target":"_blank","href":'https://dangeloconsultants.atlassian.net/browse/' + item.jiraCode},on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"icon-wrpr mr-1"},[_c('i',{staticClass:"fab fa-jira org-icon"}),_c('i',{staticClass:"far fa-external-link alt-icon"})]),_c('span',[_vm._v(_vm._s(item.jiraCode))])]):_c('span',{staticClass:"text--disabled"},[_c('i',{staticClass:"fad fa-unlink"})])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('project-status',{attrs:{"status":item.status,"small":""}})]}},{key:"item.stage",fn:function(ref){
      var item = ref.item;
return [_c('project-stage',{attrs:{"stage":item.stage,"small":""}})]}},{key:"item.fullName",fn:function(ref){
      var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.fullName))])]}},{key:"item.createDate",fn:function(ref){
      var item = ref.item;
return [_c('dater',{attrs:{"date":item.createDate,"dateonly":""}})]}},{key:"item.updateDate",fn:function(ref){
      var item = ref.item;
return [_c('dater',{attrs:{"date":item.updateDate,"dateonly":""}})]}},{key:"item.spaceCount",fn:function(ref){
      var item = ref.item;
return [_c('space-count',{attrs:{"count":item.spaceCount}})]}},{key:"item.equipmentCount",fn:function(ref){
      var item = ref.item;
return [_c('equipment-count',{attrs:{"count":item.equipmentCount}})]}},{key:"item.totalMsrpPrice",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("usdFormat")(item.totalMsrpPrice)))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","elevation":"0","color":"info","target":"_blank","href":'/projects/' + item.id},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fal fa-external-link"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }