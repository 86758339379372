<template>
  <div class="comp-wrpr project-building-selector">
    <v-autocomplete-alt
      auto-select-first
      :rules="[allRules.required]"
      :label="
        label == null || label == ''
          ? `<i class='fad fa-table-pivot mr-2'></i>Connected Building`
          : label
      "
      id="projectBuildingSelector"
      placeholder="Select a Project Building"
      :value="value"
      @input="handleSelectedProjectBuilding"
      :items="buildings"
      :filter="projectBuildingsFilter"
      dense
      filled
      item-value="id"
      item-text="name"
      clearable
      :hide-details="hideDetails"
      :loading="isProjectBuildingsLoading"
      :disabled="isProjectBuildingsLoading"
      :readonly="readonly"
      required
      :menu-props="{
        zIndex: 90000,
        contentClass: 'project-building-selector-menu',
      }"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <v-row
          :key="item + '_buildingTemplateSelector'"
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); width: 100%; flex-wrap: nowrap"
          class="py-0"
        >
          <v-col class="d-flex justify-center col-md-12 flex-column">
            <strong>
              <span class="building-building-name fs-14px">
                <i class="fad fa-building mr-2"></i> {{ data.item.name }}
              </span>
            </strong>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete-alt>
  </div>
</template>

<script>
import projectService from "../../Projects/services/projects-service";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { buildings: [], isProjectBuildingsLoading: false };
  },
  methods: {
    handleSelectedProjectBuilding(val) {
      this.$emit("input", val);
    },
    getProjectBuildings() {
      this.isProjectBuildingsLoading = true;
      projectService
        .getBuildings(this.projectId)
        .then((resp) => {
          this.buildings = resp.data.items;
          this.isProjectBuildingsLoading = false;
        })
        .catch((err) => {
          this.isProjectBuildingsLoading = false;
          this.$handleError(err);
        });
    },
    projectBuildingsFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text1.indexOf(searchText) > -1;
    },
    refresh() {
      this.getProjectBuildings();
    },
  },
  created() {
    this.getProjectBuildings();
  },
};
</script>

<style lang="scss">
.comp-wrpr.building-templates-selector {
  .v-autocomplete-alt {
    width: 100%;
    flex: 1 1 auto;
    overflow: hidden;
  }
}
</style>
