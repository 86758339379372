import Api from "../../Shared/services/api";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "DrawingDocumentNamingTemplates";
export default {
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  queryByDocumentType(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/ByDocumentType?" + qParams.toString());
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  uploadImage(imageData, config) {
    return ApiUploader().post(`${baseUrl}/Image`, imageData, config);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  getTemplateById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  getTemplateState(id) {
    return Api().get(baseUrl + `/${id}/Stats`);
  },
  getTemplateItems(id, options, type) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/${id}/Stats/${type}?${qParams.toString()}`);
  },
  checkIfNamingTemplateNameExist({ name }) {
    return Api().get(`${baseUrl}/Name/Exist?name=${name}`);
  },
  cloneNamingTemplate({ name, namingTemplateId }) {
    return Api().post(`${baseUrl}/${namingTemplateId}/Clone`, { name });
  },
};
