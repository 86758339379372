<template>
  <v-container
    fluid
    v-if="!tiny"
    style="background: #eceff1; border-radius: 0.5rem; overflow: auto; overflow: overlay"
    class="pa-2 mt-1 mb-3 inset-shadow scheme-container"
  >
    <div class="d-flex flex-row" v-if="segments != null && segments.length > 0">
      <v-sheet
        height="36px"
        elevation="2"
        class="px-3 py-1 d-flex align-center fs-14px font-weight-medium"
        v-for="element in segments"
        :color="element.color + ' lighten-4'"
        :key="'name' + element.order"
      >
        <span style="white-space: nowrap">{{ element.name }}</span>
        <span v-if="element.needValue">:</span>
        <span v-if="element.needValue" class="ml-1">
          {{ element.segmentValue }}
        </span>
      </v-sheet>
    </div>
    <span v-else class="opacity-72 fs-12px font-weight-bold">No Scheme!</span>
  </v-container>
  <span
    class="scheme-container d-inline-flex rounded-lg"
    v-else-if="segments != null && segments.length > 0"
  >
    <v-tooltip top nudge-top="-6" v-for="element in segments" :key="'name' + element.order">
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
          style="cursor: default"
          class="tiny-segment-sheet"
          v-bind="attrs"
          v-on="on"
          height="6px"
          width="20px"
          :color="element.color + ' lighten-2'"
        >
        </v-sheet>
      </template>
      <span>
        <span style="white-space: nowrap">{{ element.name }}</span>
        <span v-if="element.needValue">:</span>
        <span v-if="element.needValue" class="ml-1">{{ element.segmentValue }}</span>
      </span>
    </v-tooltip>
  </span>
  <span v-else class="opacity-72 fs-12px font-weight-bold">No Scheme!</span>
</template>

<script>
import Enums from "../../../plugins/enums";
import NamingTemplateHelper from "../services/namingTemplatesHelper";

export default {
  name: "naming-template-scheme-view",
  data() {
    return {
      segments: [],
    };
  },
  props: {
    templateText: {
      type: String,
      default: null,
    },
    tiny: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateSegments();
  },
  computed: {},
  methods: {
    updateSegments() {
      if (this.templateText != null) {
        const res = NamingTemplateHelper.parseTemplateText(this.templateText, 1);
        this.segments = res.segments;
      } else this.segments = [];
    },
  },
  watch: {
    templateText: {
      handler() {
        this.updateSegments();
      },
    },
  },
};
</script>

<style lang="scss">
.scheme-container {
  .v-sheet {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    &:last-child {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
  .tiny-segment-sheet {
    transition: all 0.15s ease-out;

    &:hover {
      transform: scale(1.5);
      box-shadow: 0px 3px 1px -2px rgba(42, 54, 59, 0.16), 0px 2px 2px 0px rgba(42, 54, 59, 0.1),
        0px 1px 5px 0px rgba(42, 54, 59, 0.08) !important;
    }
  }
}
</style>
