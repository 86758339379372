<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '950px'"
    :min-size="nested ? 400 : 950"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.clientProjects.active"
    @close="close"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small v-if="client">
        <i class="far fa-folders mr-2"></i> Connected Projects of: "{{ client.name }}"
      </h3>
      <v-btn @click="slideouts.clientProjects.active = false" icon>
        <i class="far fa-times"></i>
      </v-btn>
    </template>
    <template>
      <client-projects :client="client"></client-projects>
    </template>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()"><i class="fal fa-times mr-2"></i>Close</v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import ClientProjects from "../components/ClientProjects.vue";
export default {
  name: "client-projects-slideout",
  data() {
    return {
      client: null,

      slideouts: {
        clientProjects: {
          active: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.client = null;
      this.slideouts.clientProjects.active = false;
      this.$emit("Discard");
    },
    open(client) {
      this.client = client;
      this.slideouts.clientProjects.active = true;
    },
  },
  components: { ClientProjects },
};
</script>
