<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : 1200"
    :allow-resize="nested ? false : false"
    :visible.sync="slideouts.stats.active"
    :append-to="nested ? '' : '#app'"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small>
        <i class="fad fa-chart-pie mr-2"></i>
        Space Template Stats{{ template && template.name ? `: '${template.name}'` : null }}
      </h3>
      <v-btn @click="slideouts.stats.active = false" icon><i class="far fa-times"></i></v-btn>
    </template>
    <template>
      <div>
        <div color="white" style="position: sticky; top: 0; z-index: 1">
          <v-tabs v-model="slideouts.tab" color="error">
            <v-tab :key="0">
              Projects
              <v-chip
                :color="slideouts.tab == 0 ? 'error' : 'grey lighten-4'"
                class="ml-2 font-weight-bold"
                small
                label
              >
                {{ projectCount }}
              </v-chip>
            </v-tab>
            <v-tab :key="1">
              Buildings
              <v-chip
                :color="slideouts.tab == 1 ? 'error' : 'grey lighten-4'"
                class="ml-2 font-weight-bold"
                small
                label
              >
                {{ buildingCount }}
              </v-chip>
            </v-tab>
            <v-tab :key="2">
              Spaces
              <v-chip
                :color="slideouts.tab == 2 ? 'error' : 'grey lighten-4'"
                class="ml-2 font-weight-bold"
                small
                label
              >
                {{ spacesCount }}
              </v-chip>
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
        </div>
        <v-tabs-items v-model="slideouts.tab" class="template-stats-tabs">
          <v-tab-item :key="0">
            <space-template-has-projects
              :template="template"
              :active="slideouts.stats.active"
              :storageKey="storageKey"
            ></space-template-has-projects>
          </v-tab-item>
          <v-tab-item :key="1">
            <space-template-has-buildings
              :template="template"
              :active="slideouts.stats.active"
              :storageKey="storageKey"
            ></space-template-has-buildings>
          </v-tab-item>
          <v-tab-item :key="2">
            <space-template-has-spaces
              :template="template"
              :active="slideouts.stats.active"
              :storageKey="storageKey"
            ></space-template-has-spaces>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="slideouts.stats.active = false">
          <i class="fal fa-times mr-2"></i>
          Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import spaceTemplateService from "../services/spaceTemplates-service";
import SpaceTemplateHasBuildings from "./SpaceTemplateHasBuildings.vue";
import SpaceTemplateHasProjects from "./SpaceTemplateHasProjects.vue";
import SpaceTemplateHasSpaces from "./SpaceTemplateHasSpaces.vue";

export default {
  components: { SpaceTemplateHasBuildings, SpaceTemplateHasSpaces, SpaceTemplateHasProjects },
  name: "space-template-stats",
  data() {
    return {
      storageKey: "SpaceTemplateStats",
      template: null,
      projectCount: 0,
      buildingCount: 0,
      spacesCount: 0,
      slideouts: {
        tab: 1,
        stats: {
          active: false,
          isLoading: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTemplateStats() {
      if (this.template) {
        spaceTemplateService.getTemplateState(this.template.id).then((resp) => {
          this.projectCount = resp.data.projectsCount;
          this.buildingCount = resp.data.buildingsCount;
          this.spacesCount = resp.data.spacesCount;
        });
      }
    },
    open(template) {
      this.template = template;
      this.getTemplateStats();
      this.slideouts.stats.active = true;
      this.slideouts.tab = 0;
    },
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}
.template-stats-tabs.v-window.v-item-group.v-tabs-items {
  overflow: auto;
}
</style>
