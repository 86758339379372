<template>
  <v-container fluid class="px-6 pt-2" style="background: #eceff1">
    <v-row class="mt-2 mb-2" justify="center" align="center">
      <v-col>
        <h3>Clients that has this naming template linked to it!</h3>
      </v-col>
      <v-col lg="5" md="2" sm="6" class="d-flex justify-end">
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-2"
      :headers="headers"
      :items="entities"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      :options.sync="options"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.logoUrl != null && item.logoUrl != ''"
                  >
                    <img :key="item.id + '_img'" :src="item.logoUrl" height="100%" width="100%" />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="item.logoUrl != null && item.logoUrl != ''"
                :src="item.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h4 class="ma-0">{{ item.name }}</h4>
        </div>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" dateonly></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>

      <template v-slot:[`item.contactsCount`]="{ item }">
        <v-btn outlined color="secondary" small @click.stop rounded>
          <i class="fad fa-user-group"></i>
          <span class="ml-2 fs-12px font-weight-bold">{{ item.contactsCount }}</span>
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          elevation="0"
          color="info"
          target="_blank"
          @click.stop
          :href="`/clients/${item.id}`"
        >
          <i class="fal fa-external-link"></i>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import clientHeader from "../../Companies/Clients/config/tables/client.header";
import namingTemplateService from "../services/namingTemplates-service";

export default {
  components: {},
  name: "template-has-clients",
  props: {
    template: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    storageKey: {
      type: String,
    },
  },
  data() {
    return {
      entities: [],
      total: 0,
      clientsTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
      loadingStates: {
        table: false,
      },
      headers: clientHeader,
    };
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  methods: {
    getTemplateItems() {
      this.$backToTop(
        0,
        document.querySelector(".template-stats-tabs.v-window.v-item-group.v-tabs-items")
      );
      this.loadingStates.table = true;
      if (this.template) {
        namingTemplateService
          .getTemplateItems(this.template.id, this.$clean(this.options, true), "Clients")
          .then((resp) => {
            this.entities = resp.data.items;
            this.total = resp.data.total;
            this.loadingStates.table = false;
          })
          .catch(() => {
            this.loadingStates.table = false;
          });
      }
    },
    getProjectDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getTemplateItems();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);

      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getTemplateItems();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectDebounced();
      },
      deep: true,
    },
    active(val) {
      if (val) {
        this.options.page = 1;
        this.getProjectDebounced();
      } else {
        this.entities = [];
        this.total = 0;
      }
    },
  },
};
</script>
