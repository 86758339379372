<template>
  <div>
    <slideout
      dock="right"
      :size="nested ? '87%' : '900px'"
      :min-size="nested ? 400 : 900"
      :allow-resize="nested ? false : true"
      :append-to="nested ? '' : '#app'"
      class="naming-template-editor-slideout"
      :visible.sync="slideouts.editor.active"
      v-on:close="onSlideoutClosing"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold pa-1" small>
          <span v-if="targetScheme == 'documentNameScheme'">
            <i class="fad fa-input-text mr-2"></i> <u>Document Name</u>
          </span>
          <span v-else-if="targetScheme == 'documentNumberScheme'">
            <i class="fad fa-input-numeric mr-2"></i> <u>Document Number</u>
          </span>
          Scheme Editor for: <b>{{ targetNamingTemplateName }}</b>
        </h3>
        <div>
          <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>
      <div>
        <v-container fluid>
          <v-row class="my-0">
            <v-col cols="12" md="12" sm="12" class="py-0">
              <h3 class="mb-2">
                Available Template Segments
                <span class="fs-14px opacity-72 ml-2">
                  <i class="fad fa-circle-info mr-1"></i>Usage: Double Click or Drag & Drop
                </span>
              </h3>
              <v-container class="pa-4 segments-container mb-2">
                <draggable
                  tag="div"
                  :group="{ name: 'people', pull: 'clone', put: false }"
                  direction="vertical"
                  v-model="segments"
                  :clone="onClone"
                  :sort="false"
                  v-bind="dragOptions"
                >
                  <transition-group
                    type="transition"
                    class="d-flex flex-row flex-wrap"
                    style="gap: 0.5rem"
                  >
                    <v-sheet
                      @dblclick="cloneSegment(element)"
                      height="36px"
                      rounded="xl"
                      style="flex-wrap: nowrap; cursor: pointer"
                      elevation="2"
                      class="px-3 py-1 d-flex align-center fs-14px font-weight-medium"
                      v-for="element in segments"
                      :color="element.color + ' lighten-4'"
                      :key="element.name"
                    >
                      {{ element.name }}
                    </v-sheet>
                  </transition-group>
                </draggable>
              </v-container>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="">
              <h3 class="mb-2">Template Scheme</h3>
              <v-container
                style="
                  background: #eceff1;
                  border-radius: 0.5rem;
                  overflow: auto;
                  overflow: overlay;
                "
                class="pa-4 inset-shadow scheme-container"
              >
                <v-form v-model="slideouts.editor.valid" ref="updateForm">
                  <draggable
                    key="list-has-elms"
                    tag="div"
                    direction="vertical"
                    group="people"
                    v-model="selected"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <transition-group
                      type="transition"
                      class="d-flex flex-row"
                      :name="!drag ? 'flip-list' : null"
                    >
                      <v-sheet
                        height="36px"
                        elevation="2"
                        class="px-3 py-1 d-flex align-center fs-14px font-weight-medium"
                        v-for="(element, idx) in selected"
                        :color="element.color + ' lighten-4'"
                        :key="element.order"
                      >
                        <span style="white-space: nowrap">{{ element.name }}</span>
                        <span v-if="element.needValue">:</span>
                        <span v-if="element.needValue" class="ml-1">
                          <v-text-field-alt
                            hide-details
                            short
                            :rules="[allRules.required]"
                            v-model="element.segmentValue"
                            v-autowidth
                          ></v-text-field-alt>
                        </span>
                        <v-btn class="ml-2" icon @click="removeAt(idx)" x-small>
                          <i class="fal fa-times close fs-14px"></i>
                        </v-btn>
                      </v-sheet>
                    </transition-group>
                  </draggable>
                  <div
                    v-if="selected.length == 0"
                    key="list-no-elms"
                    class="d-flex flex-column align-center"
                  >
                    <i class="fad fa-empty-set fs-28px mb-2" style="opacity: 0.4"></i>
                    <span class="fs-16px font-weight-bold opacity-72"> No Segments Selected! </span>
                  </div>
                </v-form>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!slideouts.editor.hasChanges"
            @click="discardChanges()"
            color="secondary"
          >
            <i class="fal fa-clock-rotate-left mr-2"></i> Discard
          </v-btn>
          <v-btn
            color="info"
            :disabled="!slideouts.editor.valid || !slideouts.editor.hasChanges"
            @click="updateConfirmed()"
            :loading="slideouts.editor.isLoading"
          >
            <i class="fal fa-save mr-2"></i>Save Changes
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
  </div>
</template>

<script>
import perms from "../../../plugins/permissions";
import Enums from "../../../plugins/enums";
import NamingTemplateHelper from "../services/namingTemplatesHelper";
import draggable from "vuedraggable";

export default {
  name: "naming-template-editor",
  data() {
    return {
      perms: perms,
      docTypes: this.$options.filters.EnumToList(Enums.DOCUMENT_TYPES, true),
      targetScheme: null,
      targetNamingTemplateName: null,
      selected: [],
      selectedCemented: [],
      views: [],
      drag: false,
      lastOrder: 1,
      segments: Enums.NAMING_TEMPLATE_SEGMENTS,
      slideouts: {
        editor: {
          active: false,
          valid: false,
          hasChanges: null,
          isLoading: false,
        },
      },
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removeAt(idx) {
      this.selected.splice(idx, 1);
    },
    onClone(segment) {
      var newSegment = this.cloneDeep(segment);
      newSegment.order = this.lastOrder++;
      return newSegment;
    },
    cloneSegment(segment) {
      var newSegment = this.cloneDeep(segment);
      newSegment.order = this.lastOrder++;
      this.selected.push(newSegment);
    },
    onSlideoutClosing(e) {
      this.$emit("close");
    },
    announceChange() {
      var templateText = NamingTemplateHelper.textifyTemplateSegments(this.selected);
      this.$emit("save", templateText);
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    open(templateText, targetScheme, templateName) {
      //Parse Template
      this.lastOrder = 1;
      this.targetScheme = targetScheme;
      this.targetNamingTemplateName = templateName;
      this.selected = [];
      const res = NamingTemplateHelper.parseTemplateText(templateText, this.lastOrder);
      this.selected = this.cloneDeep(res.segments);
      this.lastOrder = res.lastOrder;
      this.$log("segments", this.cloneDeep(res.segments));
      this.cementTemplate();
      this.slideouts.editor.active = true;
    },
    cementTemplate() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    updateConfirmed() {
      this.announceChange();
      this.closeUpdateSlideout();
    },
    closeUpdateSlideout() {
      this.slideouts.editor.isLoading = false;
      this.slideouts.editor.active = false;
    },
    checkForChanges() {
      if (!this.slideouts.editor.active) return;
      this.slideouts.editor.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.slideouts.editor.active) {
          this.checkForChanges();
        }
      },
      deep: true,
    },
  },
  components: { draggable },
};
</script>

<style lang="scss">
.update-naming-template-slideout {
  .v-window.v-tabs-items {
    overflow: visible !important;
  }
}
.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.segments-container {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(42, 54, 59, 0.4)' stroke-width='3' stroke-dasharray='6%2c 6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 8px;
}
.scheme-container {
  .v-sheet {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    &:last-child {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
}
</style>
