import Enums from "../../../plugins/enums";
import { cloneDeep } from "lodash";

export default {
  parseTemplateText(templateText, startOrder = 1) {
    var segments = Enums.NAMING_TEMPLATE_SEGMENTS;
    var list = [];
    if (templateText != null && templateText != "") {
      var parseRawList = templateText.split(/[{}]+/).filter((e) => e);
      for (let i = 0; i < parseRawList.length; i++) {
        const rawItem = parseRawList[i];
        const rawSegmentParts = rawItem.split(/[()]+/).filter((e) => e);
        const rawSegmentName = rawSegmentParts[0];
        const rawSegmentValue = rawSegmentParts.length == 2 ? rawSegmentParts[1] : null;
        const segmentItem = cloneDeep(
          segments.find((s) => s.templateSegmentName == rawSegmentName)
        );
        if (segmentItem.needValue) segmentItem.segmentValue = rawSegmentValue;
        segmentItem.order = startOrder++;
        list.push(segmentItem);
      }
    }
    return {
      segments: list,
      lastOrder: startOrder,
    };
  },
  textifyTemplateSegments(segments) {
    var segmentsTexts = [];
    for (let i = 0; i < segments.length; i++) {
      const element = segments[i];
      var segmentText = "";
      if (element.needValue) {
        segmentText = `{{${element.templateSegmentName}(${element.segmentValue})}}`;
      } else {
        segmentText = `{{${element.templateSegmentName}}}`;
      }
      segmentsTexts.push(segmentText);
    }
    return segmentsTexts.join("");
  },
};
