import Api from "../../Shared/services/api";

const baseUrl = "DrawingDocuments";
export default {
  getProjectFolders(projectId) {
    return Api().get(`${baseUrl}/Folders/${projectId}`);
  },
  addFolder(entity) {
    return Api().post(`${baseUrl}/Folders/${entity.projectId}`, entity);
  },
  updateFolder(entity) {
    if (entity.id) return Api().put(`${baseUrl}/Folders/${entity.id}`, entity);
    else return this.addFolder(entity);
  },
  deleteFolder(id) {
    return Api().delete(`${baseUrl}/Folders/${id}`);
  },
  getFolderDocuments(folderId) {
    return Api().get(`${baseUrl}/Folders/${folderId}/Documents`);
  },
  getDocument(id) {
    return Api().get(`${baseUrl}/Documents/${id}`);
  },
  getProjectDocuments(projectId) {
    return Api().get(`${baseUrl}/Projects/${projectId}/Documents`);
  },
  addProjectDocument(entity) {
    return Api().post(`${baseUrl}/Projects/${entity.projectId}/Documents`, entity);
  },
  updateProjectDocument(entity) {
    if (entity.id) return Api().put(`${baseUrl}/Documents/${entity.id}`, entity);
    else return this.addProjectDocument(entity);
  },
  addDocument(entity) {
    return Api().post(`${baseUrl}/Folders/${entity.folderId}/Documents`, entity);
  },
  updateDocument(entity) {
    if (entity.id) return Api().put(`${baseUrl}/Documents/${entity.id}`, entity);
    else return this.addDocument(entity);
  },
  deleteDocument(id) {
    return Api().delete(`${baseUrl}/Documents/${id}`);
  },
  bulkCreate(projectId, entity) {
    return Api().post(`${baseUrl}/Documents/bulk-create/${projectId}`, entity);
  },
  bulkDeleteDocuments(listIds) {
    return Api().post(`${baseUrl}/Documents/bulk-delete`, listIds);
  },
  exportDrawingDocumentsTemplate(projectId) {
    return Api().get(`${baseUrl}/${projectId}/ExportDocuments`, {
      responseType: "blob",
    });
  },
  exportDrawingDocumentsFilledTemplate(projectId) {
    return Api().get(`${baseUrl}/${projectId}/ExportFilledDocuments`, {
      responseType: "blob",
    });
  },
  importDrawingDocumentsTemplate(projectId, fileData, config) {
    return Api().post(`${baseUrl}/${projectId}/ImportDocuments`, fileData, config);
  },
  cloneDocument(entity) {
    return Api().post(`${baseUrl}/Documents/${entity.id}/clone`, entity);
  },
};
