<template>
  <div class="comp-wrpr layout-templates-selector">
    <v-autocomplete-alt
      auto-select-first
      :rules="required ? [allRules.required] : []"
      :label="hideLabel ? '' : `<i class='fad fa-drafting-compass mr-2'></i>Layout Template`"
      id="layoutTemplate"
      placeholder="No Template Selected!"
      :items="layoutTemplates"
      dense
      filled
      clearable
      :hide-details="hideDetails"
      item-value="id"
      item-text="name"
      :loading="isLayoutTemplatesLoading"
      :value="value"
      @input="emitLayoutTemplate"
      :search-input.sync="options.search"
      :readonly="readonly"
      :menu-props="{
        zIndex: 90000,
        contentClass: 'layout-templates-selector-menu',
      }"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <v-row
          :key="item + '_layoutTemplateSelector'"
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); flex-wrap: nowrap"
          class="py-0"
        >
          <v-col sm="auto" class="d-flex align-center mr-2">
            <v-badge color="secondary" bordered left offset-x="20px" offset-y="34px">
              <template v-slot:badge>
                <i class="fal fa-drafting-compass mb-0" style="font-size: 10px"></i>
              </template>
              <v-tooltip right z-index="999" nudge-right="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="space-img">
                      <div
                        style="height: 100%; width: 100%"
                        v-viewer
                        @click.stop
                        v-if="data.item.imageUrl != null && data.item.imageUrl != ''"
                      >
                        <img
                          :key="data.item.id + '_img'"
                          :src="data.item.imageUrl"
                          height="100%"
                          width="100%"
                        />
                      </div>
                      <i v-else :class="'fad fa-drafting-compass'"></i>
                    </div>
                  </div>
                </template>
                <span>
                  <v-img
                    v-if="(data.item.imageUrl != null) & (data.item.imageUrl != '')"
                    :src="data.item.imageUrl"
                    height="250px"
                    width="250px"
                    contain
                  ></v-img>
                  <i
                    v-else
                    :class="'fad fa-drafting-compass'"
                    style="font-size: 32px; padding: 2rem"
                  ></i>
                </span>
              </v-tooltip>
            </v-badge>
          </v-col>
          <v-col class="d-flex justify-center col-auto flex-column">
            <strong class="typeahead-layout-template-name">{{ data.item.name }}</strong>
            <p class="typeahead-layout-template-description">
              {{ data.item.description }}
            </p>
          </v-col>
        </v-row>
      </template>
      <template #append-item>
        <div v-intersect="loadMore" />
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      :loading="isLayoutTemplatesLoading"
      :disabled="isLayoutTemplatesLoading || readonly"
      @click="getData"
    >
      <i class="fas fa-sync"></i>
    </v-btn>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      @click="viewLayoutTemplate(value)"
      :disabled="value == null"
      v-if="allowView"
    >
      <i class="fas fa-eye"></i>
    </v-btn>
    <edit-layout-template ref="addLayoutTemplate"></edit-layout-template>
  </div>
</template>

<script>
import EditLayoutTemplate from "../../DrawingDocuments/components/EditLayoutTemplate.vue";
import layoutTemplateService from "../../DrawingDocuments/services/layoutTemplates-service";
export default {
  components: { EditLayoutTemplate },
  props: {
    value: {
      type: [Number, String],
    },
    obj: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    allowView: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layoutTemplates: [],
      isLayoutTemplatesLoading: false,
      layoutTemplatesTimerId: null,
      total: 0,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
    };
  },
  methods: {
    emitLayoutTemplate(id) {
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.layoutTemplates.find((elm) => elm.id == id) : null);
    },
    getData() {
      this.isLayoutTemplatesLoading = true;
      layoutTemplateService
        .query({
          ...this.options,
          itemsPerPage: this.options.search && this.total ? this.total : this.options.itemsPerPage,
          search: this.options.search || "",
        })
        .then((resp) => {
          const isSearch = !!this.options.search;
          this.addUniqueArr(this.layoutTemplates, resp.data.items, "id", isSearch);
          if (!isSearch) this.total = resp.data.total;
          this.refreshAutocomplete();
          this.isLayoutTemplatesLoading = false;
        })
        .catch(() => {
          this.isLayoutTemplatesLoading = false;
        });
    },
    viewLayoutTemplate(id) {
      this.$refs.addLayoutTemplate.open(id);
    },
    getDataDebounced() {
      if (this.layoutTemplatesTimerId == null) {
        this.layoutTemplatesTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.layoutTemplatesTimerId);
      // delay new call 400ms
      this.layoutTemplatesTimerId = setTimeout(() => {
        this.getData();
      }, 500);
    },
    refreshAutocomplete() {
      if (this.value && (!this.obj || this.value != this.obj.id)) {
        const index = this.layoutTemplates.findIndex((elm) => elm.id == this.value);
        if (index == -1) {
          this.fetchObj();
        }
      } else {
        if (
          this.obj &&
          (this.layoutTemplates.findIndex((elm) => elm.id == this.obj.id) == -1 ||
            this.value != this.obj.id)
        ) {
          this.addAndEmit(this.obj);
        }
      }
    },
    addAndEmit(item) {
      this.addUniqueArr(this.layoutTemplates, [item], "id", true);
      this.emitLayoutTemplate(item.id);
    },
    loadMore(undefine, _, isIntersecting) {
      if (
        isIntersecting &&
        this.total > this.layoutTemplates.length &&
        !this.isLayoutTemplatesLoading
      ) {
        // this line to loadMore if there is no search
        if (!this.options.search) this.options.page++;
        // this line to clear search only if there is item selected
        if (this.value) this.options.search = "";
      }
    },
    fetchObj() {
      layoutTemplateService.getTemplateById(this.value).then((res) => {
        this.addAndEmit(res.data);
      });
    },
  },
  created() {
    this.getDataDebounced();
  },
  watch: {
    options: {
      handler() {
        this.getDataDebounced();
      },
      deep: true,
    },
    obj() {
      this.refreshAutocomplete();
    },
  },
};
</script>

<style lang="scss">
.comp-wrpr.layout-templates-selector {
  .v-autocomplete-alt {
    width: calc(100% - 40px);
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.layout-templates-selector-menu {
  max-width: 500px;
}
</style>
