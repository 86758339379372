<template>
  <v-tooltip :disabled="disableTooltip" top z-index="9999" v-if="type != null && typeObj != null">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        v-on="on"
        x-small
        label
        style="font-family: 'Cascadia Code'"
        :color="typeObj.color"
        :text-color="typeObj.textColor"
      >
        <i class="fas mr-1" style="width: 14px; line-height: 0" :class="typeObj.icon"></i>
        <span class="font-weight-medium">{{ typeObj.text }}</span>
      </v-chip>
    </template>
    <span>{{ typeObj.desc }}</span>
  </v-tooltip>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "document-type",
  data() {
    return {
      docTypes: Enums.DOCUMENT_TYPES,
      typeObj: null,
    };
  },
  props: {
    type: {
      type: Number,
      default: null,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.updateTypeObj();
  },
  computed: {},
  methods: {
    updateTypeObj() {
      if (this.type != null) this.typeObj = this.getEnumMember(this.docTypes, this.type);
    },
  },
  watch: {
    type: {
      handler() {
        this.updateTypeObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
