<template>
  <v-dialog v-model="modals.active" max-width="400px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Naming Template
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneNamingTemplateForm">
        <v-card-text>
          <v-container class="py-0">
            <v-row class="my-0" dense>
              <v-col sm="12">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.noWhiteSpaces]"
                  label="Naming Template Name"
                  placeholder="Naming Template Name"
                  v-model="modals.data.name"
                  @blur="checkIfNameExist"
                  :error-messages="modals.errorMessage"
                  :loading="modals.checkNameLoading"
                >
                </v-text-field-alt>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cloneNamingTemplateDiscard()">
            <i class="fal fa-chevron-left mr-2"></i>Discard
          </v-btn>
          <v-btn
            color="info"
            :disabled="!modals.valid || modals.checkNameLoading"
            @click="cloneNamingTemplateConfirmed()"
            :loading="modals.loading"
          >
            <i class="fal fa-check mr-2"></i> Clone
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import namingTemplatesService from "../services/namingTemplates-service";

export default {
  components: {},
  data() {
    return {
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        data: {
          name: null,
          namingTemplateId: null,
        },
      },
    };
  },
  methods: {
    open(namingTemplate) {
      this.modals.active = true;
      this.modals.data.namingTemplateId = namingTemplate.id;
      this.modals.data.name = this.namingTemplateNameConvention(namingTemplate.name);
      this.checkIfNameExist();
    },
    namingTemplateNameConvention(name) {
      return name + " [Clone]";
    },
    cloneNamingTemplateDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        data: {
          name: "",
          namingTemplateId: null,
        },
      };
    },
    checkIfNameExist() {
      if (this.modals.data.name != null && this.modals.data.name.trim() != "") {
        this.modals.checkNameLoading = true;
        namingTemplatesService
          .checkIfNamingTemplateNameExist(this.modals.data)
          .then((res) => {
            this.$log(res.data);
            if (res.data)
              this.modals.errorMessage = `"${this.modals.data.name}" name is already exists!`;
            else this.modals.errorMessage = null;
          })
          .finally(() => (this.modals.checkNameLoading = false));
      }
    },
    cloneNamingTemplateConfirmed() {
      this.modals.loading = true;
      namingTemplatesService
        .cloneNamingTemplate(this.modals.data)
        .then((res) => {
          this.$dialog.notify.success("Naming template cloned successfully!", {
            position: "top-right",
            timeout: 3000,
          });
          this.$log("cloneNamingTemplate resp.data", res.data);
          this.$emit("done", res.data);
          // this.$router.push({
          //   name: `naming-template`,
          //   params: { id: res.data.id },
          // });
          this.cloneNamingTemplateDiscard();
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.loading = false));
    },
  },
};
</script>
