<template>
  <v-card
    elevation="2"
    class="layout-preview-page"
    :class="'layout-page-' + selected.page.pageSize"
    v-if="selected.page != null"
  >
    <v-card-text>
      <div class="layout-container">
        <div
          class="layout-row"
          v-for="(row, rowIndex) in selected.page.rows"
          :class="'layout-row-cols-' + row.cols.length"
          :key="'p' + selected.page.pageNum + '_r' + row.rowNum"
          :data-row-name="'Row: ' + row.rowNum"
        >
          <v-tooltip top nudge-top="-6px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="error"
                class="delete-row-btn"
                ref="deleteRowBtn"
                @click="deleteRow(row, rowIndex)"
                :disabled="disabled"
              >
                <i class="fad fa-trash-alt"></i>
              </v-btn>
            </template>
            <span>Delete Row</span>
          </v-tooltip>

          <v-tooltip top nudge-top="-6px" v-if="rowIndex > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                class="move-row-up-btn"
                ref="moveRowUpBtn"
                @click="moveRowUp(row, rowIndex)"
                :disabled="disabled"
              >
                <i class="far fa-long-arrow-alt-up"></i>
              </v-btn>
            </template>
            <span>Move Up</span>
          </v-tooltip>

          <v-tooltip top nudge-top="-6px" v-if="rowIndex < selected.page.rows.length - 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                class="move-row-down-btn"
                ref="moveRowDownBtn"
                @click="moveRowDown(row, rowIndex)"
                :disabled="disabled"
              >
                <i class="far fa-long-arrow-alt-down"></i>
              </v-btn>
            </template>
            <span>Move Down</span>
          </v-tooltip>

          <v-tooltip top nudge-top="-6px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="warning"
                class="add-row-col-btn"
                ref="addRowColBtn"
                height="20px"
                @click="addRowColumn(row, rowIndex)"
                :disabled="row.cols == null || row.cols.length >= 6 || disabled"
              >
                <i class="far fa-plus"></i>
              </v-btn>
            </template>
            <span>Add Column</span>
          </v-tooltip>

          <div
            class="layout-col"
            v-for="(col, colIndex) in row.cols"
            :key="'p' + selected.page.pageNum + '_r' + row.rowNum + '_c' + col.colNum"
            :order="col.colNum"
            :data-col-name="'Col: ' + col.colNum"
          >
            <v-tooltip top nudge-top="-6px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  class="delete-col-btn"
                  ref="deleteRowColBtn"
                  @click="deleteColumn(row, rowIndex, col, colIndex)"
                  :disabled="disabled"
                >
                  <i class="fad fa-trash-alt"></i>
                </v-btn>
              </template>
              <span>Delete Column</span>
            </v-tooltip>
            <div class="layout-section">
              <div class="section-content">
                <v-select-alt
                  placeholder="Select View"
                  v-model="col.viewId"
                  :items="viewsList"
                  :clearable="!disabled"
                  class="d-inline-flex"
                  item-text="name"
                  item-value="id"
                  hide-details
                  @change="onViewChanged($event, row, rowIndex, col, colIndex)"
                  :readonly="disabled"
                >
                </v-select-alt>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="pa-1">
      <div class="px-2 d-flex flex-row justify-space-between align-center" style="width: 100%">
        <span>
          <span class="font-weight-bold mr-2">
            Page {{ selected.page.pageNum }} of {{ totalPages }}
          </span>
          <v-chip
            color="secondary"
            class="mono-font"
            small
            style="height: 20px"
            v-if="selected.page.pageSize == 'arch-e-landscape'"
          >
            <span
              class="fa-stack mr-1"
              style="font-size: 16px; width: 16px; height: 16px; line-height: 16px"
            >
              <i class="fad fa-rectangle fa-stack-1x" style="font-size: 16px"></i>
              <i class="fas fa-e fa-stack-1x" style="font-size: 10px"></i>
            </span>
            Arch E
          </v-chip>
          <v-chip
            color="secondary"
            class="mono-font"
            small
            style="height: 20px"
            v-else-if="selected.page.pageSize == 'arch-d-landscape'"
          >
            <span
              class="fa-stack mr-1"
              style="font-size: 16px; width: 16px; height: 16px; line-height: 16px"
            >
              <i class="fad fa-rectangle fa-stack-1x" style="font-size: 16px"></i>
              <i class="fas fa-d fa-stack-1x" style="font-size: 10px"></i>
            </span>
            Arch D
          </v-chip>
          <v-chip
            color="secondary"
            class="mono-font"
            small
            style="height: 20px"
            v-else-if="selected.page.pageSize == 'arch-b-landscape'"
          >
            <span
              class="fa-stack mr-1"
              style="font-size: 16px; width: 16px; height: 16px; line-height: 16px"
            >
              <i class="fad fa-rectangle fa-stack-1x" style="font-size: 16px"></i>
              <i class="fas fa-b fa-stack-1x" style="font-size: 10px"></i>
            </span>
            Arch B
          </v-chip>
          <v-chip
            color="secondary"
            class="mono-font"
            small
            style="height: 20px"
            v-else-if="selected.page.pageSize == 'arch-super-b-landscape'"
          >
            <span
              class="fa-stack mr-1"
              style="font-size: 16px; width: 16px; height: 16px; line-height: 16px"
            >
              <i class="fad fa-shield fa-stack-1x" style="font-size: 16px"></i>
              <i class="fas fa-b fa-stack-1x" style="font-size: 10px"></i>
            </span>
            Arch Super B
          </v-chip>
        </span>
        <div class="d-flex" style="gap: 0.5rem">
          <v-btn
            color="info"
            text
            style="text-transform: none"
            ref="addRowBtn"
            @click="addRow"
            :disabled="selected.page.rows == null || selected.page.rows.length >= 3 || disabled"
          >
            <i class="fal fa-plus mr-2"></i> New Row
          </v-btn>
          <v-btn
            color="error"
            text
            style="text-transform: none"
            ref="deletePageBtn"
            @click="deletePage"
            :disabled="disabled"
          >
            <i class="fal fa-trash-alt mr-2"></i> Delete Page
          </v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "layout-page",
  data() {
    return {
      selected: {
        page: null,
      },
      viewsList: [],
      layoutTypes: Enums.DOCUMENT_LAYOUT_LIST,
    };
  },
  computed: {},
  props: {
    value: {
      type: Object,
      default: null,
    },
    totalPages: {
      type: Number,
      default: null,
    },
    views: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.selected.page = this.value;
    this.viewsList = this.views;
    this.$log("Mounted", this.viewsList);
  },
  methods: {
    onViewChanged(viewId, row, rowIndex, col, colIndex) {
      this.$emit("view-assigned", {
        viewId: viewId,
        col: col,
        colIndex: colIndex,
        row: row,
        rowIndex: rowIndex,
        page: this.selected.page,
      });
    },
    addRow() {
      let row = {
        rowNum: this.selected.page.rows.length + 1,
        cols: [
          {
            colNum: 1,
            viewId: null,
          },
          {
            colNum: 2,
            viewId: null,
          },
        ],
      };

      this.selected.page.rows.push(row);
      this.$emit("input", this.selected.page);
    },
    addRowColumn(row, rowIndex) {
      let col = {
        colNum: this.selected.page.rows[rowIndex].cols.length + 1,
        viewId: null,
      };

      this.selected.page.rows[rowIndex].cols.push(col);
      this.$emit("input", this.selected.page);
    },
    deleteRow(row, rowIndex) {
      this.selected.page.rows.splice(rowIndex, 1);
      //update row nums
      for (let i = 0; i < this.selected.page.rows.length; i++) {
        const row = this.selected.page.rows[i];
        row.rowNum = i + 1;
      }
      this.$emit("input", this.selected.page);
    },
    deleteColumn(row, rowIndex, col, colIndex) {
      this.selected.page.rows[rowIndex].cols.splice(colIndex, 1);
      //update col nums
      for (let i = 0; i < this.selected.page.rows[rowIndex].cols.length; i++) {
        const col = this.selected.page.rows[rowIndex].cols[i];
        col.colNum = i + 1;
      }
      this.$emit("input", this.selected.page);
    },
    deletePage() {
      this.$emit("delete");
    },
    swapRows(a, b) {
      let rowA = this.cloneDeep(this.selected.page.rows[a]);
      let rowB = this.cloneDeep(this.selected.page.rows[b]);
      this.selected.page.rows[a].cols = rowB.cols;
      this.selected.page.rows[b].cols = rowA.cols;
    },
    moveRowUp(row, rowIndex) {
      this.swapRows(rowIndex, rowIndex - 1);
    },
    moveRowDown(row, rowIndex) {
      this.swapRows(rowIndex, rowIndex + 1);
    },
  },
  watch: {
    value: {
      handler() {
        this.selected.page = this.value;
      },
      deep: true,
    },
    views: {
      handler() {
        this.viewsList = this.views;
      },
      deep: true,
    },
  },
  components: {},
};
</script>

<style lang="scss">
.layout-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;

  .layout-row {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    transition: all 0.25s ease-out;
    border: 1.5px dashed $info-base;
    border-radius: 0.5rem;
    flex-grow: 1;

    .delete-row-btn {
      position: absolute;
      font-size: 12px;
      line-height: 1;
      z-index: 10;
      left: calc(50% + 36px);
      left: 4rem;
      top: -0.15rem;
      transform: translate(-50%, -50%);
      transform: translateY(-50%);
      transform: translateY(-50%) scale(0);
      transition: all 0.25s ease-out;
      text-transform: none;
      width: 18px;
      height: 18px !important;
      min-width: 18px;
      padding: 0.5rem 0.5rem !important;
      opacity: 0;
    }

    .move-row-up-btn {
      position: absolute;
      font-size: 12px;
      line-height: 1;
      z-index: 10;
      left: calc(50% + 36px);
      left: 50%;
      top: -0.15rem;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.25s ease-out;
      text-transform: none;
      width: 18px;
      height: 18px !important;
      min-width: 18px;
      padding: 0.5rem 0.5rem !important;
      opacity: 0;
    }

    .move-row-down-btn {
      position: absolute;
      font-size: 12px;
      line-height: 1;
      z-index: 10;
      left: calc(50% + 36px);
      left: 50%;
      bottom: 0.15rem;
      transform: translate(-50%, 50%) scale(0);
      transition: all 0.25s ease-out;
      text-transform: none;
      width: 18px;
      height: 18px !important;
      min-width: 18px;
      padding: 0.5rem 0.5rem !important;
      opacity: 0;
    }
    .add-row-col-btn {
      position: absolute;
      font-size: 12px;
      line-height: 1;
      z-index: 10;
      right: 0rem;
      top: 50%;
      transform: translate(50%, -50%) scale(0);
      transition: all 0.25s ease-out;

      text-transform: none;
      width: 20px;
      height: 20px !important;
      min-width: 20px;
      padding: 0.5rem 0.5rem !important;

      opacity: 0;
    }

    .layout-col {
      position: relative;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      transition: all 0.25s ease-out;

      .delete-col-btn {
        position: absolute;
        font-size: 12px;
        line-height: 1;
        z-index: 10;
        left: 4rem;
        left: calc(50% + 36px);
        top: 0;
        transform: translateY(-50%);
        transform: translate(-50%, -50%);
        transform: translate(-50%, -50%) scale(0);
        transition: all 0.25s ease-out;
        text-transform: none;
        width: 18px;
        height: 18px !important;
        min-width: 18px;
        padding: 0.5rem 0.5rem !important;
        opacity: 0;
      }

      &:hover {
        background: rgba($orange-base, 0.1);

        .delete-col-btn {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }

      &:before {
        content: attr(data-col-name);
        padding: 0.1rem 0.25rem;
        border-radius: 0.25rem;
        position: absolute;
        font-size: 12px;
        line-height: 1;
        left: 0.65rem;
        left: 50%;
        top: 0.15rem;
        transform: translateY(-50%);
        transform: translate(-50%, -50%);
        color: #fff;
        background: $orange-base;
      }
    }

    &:hover {
      background: rgba($info-base, 0.1);

      .delete-row-btn {
        opacity: 1;
        transform: translateY(-50%) scale(1);
      }
      .add-row-col-btn {
        opacity: 1;
        transform: translate(50%, -50%) scale(1);
      }
      .move-row-up-btn {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
      .move-row-down-btn {
        opacity: 1;
        transform: translate(-50%, 50%) scale(1);
      }
    }

    &:before {
      content: attr(data-row-name);
      padding: 0.1rem 0.25rem;
      border-radius: 0.25rem;
      position: absolute;
      font-size: 12px;
      line-height: 1;
      left: 50%;
      left: 0.65rem;
      top: -0.15rem;
      transform: translate(-50%, -50%);
      transform: translateY(-50%);
      color: #fff;
      background: $info-base;
    }
  }
}

.layout-preview-page {
  border: 1.5px dashed rgba($shades-black, 0.4) !important;

  .v-card__text {
    height: 100%;
  }

  .v-card__actions {
    border-top: 1.5px dashed rgba($shades-black, 0.4) !important;
    background: rgba($shades-black, 0.05);
    background: #f4f5f5;
    position: sticky;
    bottom: 0;
  }

  &.layout-page-arch-e .v-card__text {
    aspect-ratio: 36 / 48;
  }

  &.layout-page-arch-d .v-card__text {
    aspect-ratio: 24 / 36;
  }

  &.layout-page-arch-b .v-card__text {
    aspect-ratio: 12 / 18;
  }

  &.layout-page-arch-super-b .v-card__text {
    aspect-ratio: 13 / 19;
  }

  &.layout-page-arch-e-landscape .v-card__text {
    aspect-ratio: 48 / 36;
  }

  &.layout-page-arch-d-landscape .v-card__text {
    aspect-ratio: 36 / 24;
  }

  &.layout-page-arch-b-landscape .v-card__text {
    aspect-ratio: 18 / 12;
  }

  &.layout-page-arch-super-b-landscape .v-card__text {
    aspect-ratio: 19 / 13;
  }

  .layout-section {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100px;
    border-radius: 0.5rem;
    border: 1.5px dashed $orange-base;
    padding: 0.5rem;
    gap: 0.5rem;

    .section-content {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .v-chip {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
</style>
