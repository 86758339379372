<template>
  <slideout
    :size="nested ? '87%' : '650px'"
    :min-size="nested ? 400 : 650"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    dock="right"
    :visible.sync="slideouts.active"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small v-if="client">
        <i class="far fa-code-merge mr-2"></i> Merge Client ({{ client.name }})
        with
        <span v-if="modals.client">({{ modals.client.name }})</span>
      </h3>
    </template>

    <template>
      <v-card flat>
        <v-form v-model="modals.valid" ref="mergeForm">
          <v-card-text>
            <v-autocomplete-alt
              auto-select-first
              :rules="[allRules.required]"
              label="Choose Client"
              v-model="modals.client"
              :search-input.sync="clients.search"
              placeholder="Choose Client"
              :items="clients.entities"
              dense
              filled
              return-object
              item-text="name"
              item-value="id"
              :loading="clients.isLoading"
            >
            </v-autocomplete-alt>
          </v-card-text>
        </v-form>
        <client-projects :client="client"></client-projects>
      </v-card>
    </template>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="discardMerge()"
          ><i class="fal fa-chevron-left mr-2"></i>Discard</v-btn
        >
        <v-btn
          color="info"
          :loading="modals.isLoading"
          :disabled="!modals.valid || modals.isLoading"
          @click="mergeConfirmed()"
        >
          <i class="fal fa-check mr-2"></i> Merge
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import ApiService from "../services/clients-service";
import ClientProjects from "../components/ClientProjects.vue";

export default {
  name: "merge-client",
  data() {
    return {
      modals: {
        client: null,
        valid: false,
        isLoading: false
      },
      client: null,
      clients: {
        search: "",
        entities: [],
        isLoading: false
      },
      slideouts: {
        active: false,
        isLoading: false
      }
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    mergeConfirmed() {
      this.modals.isLoading = true;
      ApiService.merge({
        sourceClientId: this.client.id,
        targetClientId: this.modals.client.id
      })
        .then(() => {
          var message = "Client merged successfully!";
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000
          });
          this.$emit("refetch");
          this.discardMerge();
        })
        .catch(err => {
          this.modals.isLoading = false;
          this.$handleError(err);
        });
    },
    discardMerge() {
      this.modals = {
        client: null,
        valid: false,
        isLoading: false
      };
      this.clients = {
        search: "",
        entities: [],
        isLoading: false
      };

      this.client = null;
      this.$emit("update:client", null);
      this.slideouts.active = false;

      this.$emit("Discard");
    },
    open(client) {
      this.client = client;
      this.slideouts.active = true;
    }
  },
  watch: {
    "clients.search": {
      immediate: true,
      handler(val) {
        // Items have already been requested
        if (this.clients.isLoading) return;
        this.clients.isLoading = true;
        // Lazily load input items
        ApiService.typeHead(val, true)
          .then(resp => {
            this.clients.entities = resp.data;
          })
          .finally(() => {
            this.clients.isLoading = false;
          });
      }
    }
  },
  components: { ClientProjects }
};
</script>
