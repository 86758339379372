var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6 pt-2",staticStyle:{"background":"#eceff1"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2 mb-2",attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('h3',[_vm._v("Buildings that has spaces which are linked to this space template!")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"5","md":"2","sm":"6"}},[_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.entities,"server-items-length":_vm.total,"items-per-page":15,"loading":_vm.loadingStates.table,"options":_vm.options,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.name))])]}},{key:"item.createDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.createDate,"dateonly":""}})]}},{key:"item.updateDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.updateDate,"dateonly":""}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","elevation":"0","color":"info","target":"_blank","href":("/projects/" + (item.projectId) + "/buildings/" + (item.id))},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fal fa-external-link"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }