export default [
  {
    text: "Active Key",
    value: "isActive",
    width: "100px",
    sortable: false,
  },
  {
    text: "Group",
    value: "name",
    class: "px-1",
    cellClass: "py-2 px-0",
    sortable: false,
  },
  {
    text: "Actions",
    value: "actions",
    align: "left",
    width: "100px",
    sortable: false,
  },
];
