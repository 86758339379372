import Enums from "../../../../plugins/enums";
function getDocType(enumValue) {
  // this.$log(enumValue, Enums.DOCUMENT_TYPES);
  for (var val in Enums.DOCUMENT_TYPES) {
    if (Enums.DOCUMENT_TYPES[val].value == enumValue) return Enums.DOCUMENT_TYPES[val];
  }
  return null;
}

export default [
  {
    text: "",
    value: "actions",
    order: 0,
    sortable: false,
    width: "64px",
  },
  {
    text: "Type",
    value: "type",
    width: "80px",
    sort: (a, b) => getDocType(a).text.localeCompare(getDocType(b).text),
  },
  { text: "Name", value: "name" },
  { text: "Sheet Number", value: "number" },
  { text: "State", value: "state", width: "130px" },
  { text: "Created", value: "createDate", width: "120px" },
  { text: "Last Update", value: "updateDate", width: "120px" },
];
